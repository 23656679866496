<template>
  <div class="register">
    <PageSubHeader :pageSubHeader="pageSubHeader" :key="componentKey"/>

    <main>
      <section id="form_detail" class="register_online_courses_grocery">
        <div class="w1300">

          <!-- 表單主標題 -->
          <div class="main_title_box">
            <h3 class="main_title txt-bold">課程訂購清單</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <form id="form_register_online_courses_grocery">

            <!-- table area -->
            <div class="table_box">
              <table class="txt-center">
                <thead>
                  <tr>
                    <th class="min150"><p>{{ tableHead1 }}</p></th>
                    <th class="td-main txt-left"><p>{{ tableHead2 }}</p></th>
                    <th class="min130"><p>{{ tableHead3 }}</p></th>
                    <th class="min200"><p>{{ tableHead4 }}</p></th>
                    <th class="min130"><p>{{ tableHead5 }}</p></th>
                  </tr>
                </thead>
                <tbody id="table_data">
                  <tr v-for="data in computedTableData" :key="data.id">
                    <td :data-title="tableHead1" class="td-fraction min150">
                      <div class="fraction_container">
                        <p
                          class="small txt-bold type-A"
                          v-if="data.fraction_a && data.fraction_a * 1 !== 0"
                        >
                          <span class="txt-regular type-A">
                            A類</span> ∙ <span class="points">
                            {{ data.fraction_a }}學分</span>
                        </p>
                        <p
                          class="small txt-bold type-B"
                          v-if="data.fraction_b && data.fraction_b * 1 !== 0"
                        >
                          <span class="txt-regular type-B">
                            B類</span> ∙ <span class="points">
                            {{ data.fraction_b }}學分</span>
                        </p>
                        <p><span class="small txt-dark_gray">{{ data.hours }}小時</span></p>
                      </div>
                    </td>
                    <td :data-title="tableHead2" class="td-main txt-left">
                      <p>{{ data.name }}</p>
                    </td>
                    <td :data-title="tableHead3"><p>{{ data.video_count }}</p></td>
                    <td :data-title="tableHead4" class="txt-right">
                      <p>
                        <span
                          v-if="data.price[1] && data.price[1].price"
                          class="price"
                        >專科醫師：{{ data.price[1].price }}</span>
                        <span
                          v-if="data.price[2] && data.price[2].price"
                          class="price"
                        >會員醫師：{{ data.price[2].price }}</span>
                        <span
                          v-if="data.price[3] && data.price[3].price"
                          class="price"
                        >非會員醫師：{{ data.price[3].price }}</span>
                        <span
                          v-if="data.price[4] && data.price[4].price"
                          class="price"
                        >護理人員：{{ data.price[4].price }}</span>
                      </p>
                    </td>
                    <td :data-title="tableHead5" class="td-trash">
                      <img
                        src="@/statics/img/icon/trash.svg"
                        alt=""
                        @click="removeItem(data.uuid)"
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="payment_check txt-right cf active">
              <p class="payment_check_total">
                專科醫師 總金額 <span class="txt-light_green">{{ totalMemberProPrice }}</span> 元
              </p>
              <p class="payment_check_total">
                會員醫師 總金額 <span class="txt-light_green">{{ totalMemberPrice }}</span> 元
              </p>
              <p class="payment_check_total">
                非會員醫師 總金額 <span class="txt-light_green">{{ totalNonMemberPrice }}</span> 元
              </p>
              <p class="payment_check_total">
                護理人員 總金額 <span class="txt-light_green">{{ totalNursePrice }}</span> 元
              </p>
              <div class="filter_box payment_check_container fright flex_box">
                <div
                  class="btn btn-red p"
                  :class="{ disable: isCartEmpty }"
                  @click="authRequired"
                >本會會員報名</div>
                <router-link
                  v-if="!isAuthenticated"
                  to="/register/online/grocery/payment?member=f"
                  class="btn btn-green p"
                  :class="{ disable: isCartEmpty }"
                >非會員報名</router-link>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterOnlineSubHeader } from '@/lib/const';
import { mapGetters } from 'vuex';
import { courseCar } from '@/lib/http';

export default {
  name: 'RegisterOnlineGrocery',
  data() {
    return {
      /** Static Data */
      ...RegisterOnlineSubHeader,
      tableHead1: '積分/時數',
      tableHead2: '課程名稱',
      tableHead3: '集數',
      tableHead4: '金額',
      tableHead5: '移除',
      componentKey: 0,

      /** Axios Data */
      tableData: [
        // {
        //   id: 1,
        //   credit: {
        //     hour: '6',
        //     tag: 'A',
        //     point: '3',
        //   },
        //   name: '末期病人之家屬的照護及悲傷輔導之臨床實務運用',
        //   episode: '13',
        //   price: {
        //     member: '1980',
        //     nonMember: '2980',
        //     nurse: '3980',
        //   },
        // },
        // {
        //   id: 2,
        //   credit: {
        //     hour: '6',
        //     tag: 'B',
        //     point: '3',
        //   },
        //   name: '安寧緩和醫療條例與相關法律之臨床運用',
        //   episode: '13',
        //   price: {
        //     member: '1980',
        //     nonMember: '2980',
        //     nurse: '3980',
        //   },
        // },
        // {
        //   id: 3,
        //   credit: {
        //     hour: '6',
        //     tag: '',
        //     point: '',
        //   },
        //   name: '乙類安寧居家療護 - 109年度社區安寧照護(乙類)醫護人員教育訓練',
        //   episode: '13',
        //   price: {
        //     member: '1980',
        //     nonMember: '2980',
        //     nurse: '3980',
        //   },
        // },
      ],
      removeTargetId: [],
    };
  },
  created() {
    const courseInCart = JSON.parse(this.$cookies.get('courseInCart'));
    // console.log('course in car', courseInCart);
    if (courseInCart && courseInCart !== []) {
      courseCar({ course: courseInCart })
        .then((res) => {
          if (res) {
            this.tableData = res;
          }
        });
    }
  },
  // updated() {
  //   console.log(JSON.parse(this.$cookies.get('courseInCart')));
  //   if (JSON.parse(this.$cookies.get('courseInCart')) !== []) {
  //     request({
  //       url: '/courses/car',
  //       method: 'post',
  //       data: toFormData({ course: JSON.parse(this.$cookies.get('courseInCart')) }),
  //     }).then((res) => {
  //       console.log(res);
  //       this.tableData = res.data.result.data;
  //     });
  //   }
  // },
  computed: {
    ...mapGetters([
      'isAuthenticated',
    ]),
    isCartEmpty() {
      if (this.tableData.length > 0) {
        return false;
      }
      return true;
    },
    computedTableData() {
      return this.tableData.filter((data) => !this.removeTargetId.includes(data.id));
    },
    totalMemberProPrice() {
      let amount = 0;
      this.tableData.forEach((data) => {
        if (data.price[1]) {
          amount += data.price[1].price * 1;
        } else {
          amount += 0;
        }
      });
      return amount;
    },
    totalMemberPrice() {
      let amount = 0;
      this.tableData.forEach((data) => {
        if (data.price[2]) {
          amount += data.price[2].price * 1;
        } else {
          amount += 0;
        }
      });
      return amount;
    },
    totalNonMemberPrice() {
      let amount = 0;
      this.tableData.forEach((data) => {
        if (data.price[3]) {
          amount += data.price[3].price * 1;
        } else {
          amount += 0;
        }
      });
      return amount;
    },
    totalNursePrice() {
      let amount = 0;
      this.tableData.forEach((data) => {
        if (data.price[4]) {
          amount += data.price[4].price * 1;
        } else {
          amount += 0;
        }
      });
      return amount;
    },
  },
  methods: {
    removeItem(dataId) {
      // this.removeTargetId.push(dataId);
      const courseIdArray = JSON.parse(this.$cookies.get('courseInCart'));
      const targetIndex = courseIdArray.indexOf(dataId);
      if (courseIdArray.length > 1) {
        courseIdArray.splice(targetIndex, 1);
      } else {
        courseIdArray.pop();
      }
      this.$cookies.set('courseInCart', JSON.stringify(courseIdArray));
      this.forceRerender();
      if (courseIdArray.length > 0) {
        courseCar({ course: courseIdArray }).then((res) => {
          this.tableData = res;
        });
      } else {
        this.tableData = [];
      }
    },
    authRequired() {
      const targetFullPath = '/register/online/grocery/payment?member=t&user_type=1';
      if (this.isAuthenticated) {
        this.$router.push({ path: targetFullPath });
      } else {
        this.$router.push({ path: '/login', query: { redirect: targetFullPath } });
      }
    },
    timeFormat(sec) {
      if (sec >= 3600) {
        const h = Math.floor(sec / 3600);
        const m = Math.floor((sec % 3600) / 60);
        const s = sec % 60;
        return `${h}時${m}分${s}秒`;
      // eslint-disable-next-line no-else-return
      } else if (sec >= 60) {
        const m = Math.floor(sec / 60);
        const s = sec % 60;
        return `${m}分${s}秒`;
      }
      return `${sec}秒`;
    },
    /** Force PageSubheader To Update Its Count */
    forceRerender() {
      this.componentKey += 1;
    },
  },
  components: {
    PageSubHeader,
  },
};
</script>
